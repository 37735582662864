import React, { Component } from 'react';
import Page from '../components/Page';
import { NodeCard } from '../components/Card';
import { buildUrl } from '../utils/utils';
import classnames from 'classnames';

import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Col,
  Row,
  Container,
  Badge,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  CardTitle,
} from 'reactstrap';

import HistoryTable from '../components/historyView';
import ImagesPage from './ImagesPage';
import InspectionPageV2 from './InspectionPageV2';
import InspectionsCalendarPage from './InspectionsCalendarPage';
import GLMVideosPage from './GLMVideosPage';
import BellPage from './BellPage';
import GLMDevice from './GLMDevice';
import GLMData from './GLMData';
import PowerIdicationTable from '../components/PowerIndications';

export default class SLMPage extends Component {
   toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setActiveTab(tab);
    }
  }

  setActiveTab(tab) 
  {
  this.setState({activeTab: tab});


  }
  constructor() {
    super();
    this.state = {
      selectedDevice: undefined,
      activeTab:"1",
      newImage: undefined,
    };
    this.handleCaptureImage = this.handleCaptureImage.bind(this);
  }

  componentDidMount() {
    if (this.props.location.state) {
      console.log(this.props.location.state.data.MACAddress);
      
      this.setState({
        selectedDevice: this.props.location.state.data,
      });
    }
  }

  handleCaptureImage(index) {
    let data = { Command: 'CaptureImage', Node_ID: [index + 1] };
    fetch(
      buildUrl('/api/commands', {
        mac_address: this.state.selectedDevice.MACAddress,
      }),
      {
        method: 'PUT', // or 'PUT'
        mode: 'cors',
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then(res => res.json())
      .then(response => console.log('Success:', JSON.stringify(response)))
      .catch(error => console.error('Error:', error));
  }

  render() {
    let activeTab=this.state.activeTab;
    let toggle =  this.toggle.bind(this);
    if(this.state.selectedDevice===undefined) return;
    let MACAddress = this.state.selectedDevice.MACAddress;
    return (
      <Page
        className="ButtonPage"
        title="Crossing Devices"
        breadcrumbs={[
          { name: 'Crossings', active: true },
          { name: 'Inspection Review', active: true },
        ]}
      > 
          
          <React.Fragment>
     
        <Nav tabs>
        <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '1' })}
              onClick={() => {
                toggle('1');
              }}
            >
              Inspection Form
            </NavLink>
          </NavItem>
          
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '3' })}
              onClick={() => {
                toggle('3');
              }}
            >
              Flash Rate
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '4' })}
              onClick={() => {
                toggle('4');
              }}
            >
              Images
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '5' })}
              onClick={() => {
                toggle('5');
              }}
            >
              Bell Audios
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '6' })}
              onClick={() => {
                toggle('6');
              }}
            >
              GateArm Videos
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '2' })}
              onClick={() => {
              toggle('2');
              }}
            >
              SLM
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '7' })}
              onClick={() => {
              toggle('7');
              }}
            >
              GLM
            </NavLink>
          </NavItem>
          <NavItem>
        
            <NavLink
              className={classnames({ active: activeTab === '8' })}
              onClick={() => {
              toggle('8');
              }}
            >
              PowerLight Indications
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '9' })}
              onClick={() => {
              toggle('9');
              }}
            >
              GLM Counts
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === '10' })}
              onClick={() => {
              toggle('10');
              }}
            >
              Inspection Calendar
            </NavLink>
          </NavItem>
         
        </Nav>   
        <TabContent activeTab={activeTab}>
          <TabPane tabId="2">
          <NodeCard
            selectDevice={this.state.selectedDevice}
            Nodes={this.state.selectedDevice.Nodes}
            GateWayId={this.state.selectedDevice.ID}
            NodeId={this.state.selectedDevice.MACAddress}
            nodedata={this.state.nodeData}
            MACAddress={this.state.selectedDevice.MACAddress}
            Name={this.state.selectedDevice.Name}
            handleCaptureImage={this.handleCaptureImage}
          />
            </TabPane> 
        <TabPane tabId="3">
            <Row>
              <Col sm="12">
                {activeTab === '3'&&MACAddress && <HistoryTable MACAddress={MACAddress}  Nodes={this.state.selectedDevice.Nodes}/>}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col sm="12">
                {activeTab === '4'&&MACAddress && <ImagesPage MACAddress={MACAddress}  Nodes={this.state.selectedDevice.Nodes}/>}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="5">
            <Row>
              <Col sm="12">
                {activeTab === '5'&&MACAddress && <BellPage MACAddress={MACAddress} Nodes={this.state.selectedDevice.Nodes}/>}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                {activeTab === '1'&&MACAddress && <InspectionPageV2 MACAddress={MACAddress} />}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="6">
            <Row>
              <Col sm="12">
                {activeTab === '6'&&MACAddress &&     <GLMVideosPage
              MacAddress={MACAddress}
            ></GLMVideosPage>}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="7">
            <Row>
              <Col sm="12">
                {activeTab === '7' && MACAddress &&      <GLMDevice
                MacAddress={MACAddress}
              ></GLMDevice>            }
              </Col>
            </Row>
          </TabPane>         
          <TabPane tabId="8">
            <Row>
              <Col sm="12">
                {activeTab === '8' && MACAddress &&      <PowerIdicationTable
                MACAddress={MACAddress}
              ></PowerIdicationTable>            }
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="9">
            <Row>
              <Col sm="12">
                {activeTab === '9' && MACAddress &&     <GLMData  MacAddress={MACAddress}></GLMData>}
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="10">
            <Row>
              <Col sm="12">
                {activeTab === '10' && MACAddress &&     <InspectionsCalendarPage  MacAddress={MACAddress}></InspectionsCalendarPage>}
              </Col>
            </Row>
          </TabPane>
        </TabContent>
     
    </React.Fragment>
      
                  
          
       
        
      </Page>
    );
  }
}
