import React, { useState, useEffect, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Card, CardBody, Modal, ModalHeader, ModalBody, Spinner, Button, ButtonGroup } from 'reactstrap';
import Page from '../components/Page';
import moment from 'moment';
import { buildUrl } from '../utils/utils';

// Constants
const MONTH_VIEW = 'month';
const WEEK_VIEW = 'week';

// Setup localizer
const localizer = momentLocalizer(moment);

const InspectionsCalendarPage = ({ MacAddress }) => {
  const dateFromObjectId = useCallback((objectId) => {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
  }, []);
  // State management
  const [inspections, setInspections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedInspection, setSelectedInspection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);



  // Format inspections for calendar view
  const formatInspectionsForCalendar = useCallback((data) => {
    return data.map(inspection => ({
      id: inspection._id,
      title: `${inspection.CrossingInfo.AssetID} - ${inspection.CrossingInfo.Location}`,
      start: dateFromObjectId(inspection._id),
      end: dateFromObjectId(inspection._id),
      resource: inspection
    }));
  }, [dateFromObjectId]);

  // Fetch inspections data
  const getData = useCallback(async () => {
    try {
      const response = await fetch(buildUrl(`/api/inspections/eip/${MacAddress}`), {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const json = await response.json();
      json.reverse();
      setInspections(formatInspectionsForCalendar(json));
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  }, [formatInspectionsForCalendar, MacAddress, setInspections, setLoading]);

  // Event handlers
  const handleSelectEvent = (event) => {
    setSelectedInspection(event.resource);
    setIsModalOpen(true);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (isModalOpen) {
      setSelectedInspection(null);
    }
  };

  // Custom event component to show status and details
  const EventComponent = ({ event }) => (
    <div className="calendar-event">
      <strong>{event.title}</strong>
    </div>
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Page
   
    >
      <Card>
        <CardBody>
          {loading ? (
            <Spinner
              style={{
                position: 'absolute',
                width: '50px',
                height: '50px',
                top: '50%',
                left: '50%',
              }}
            >
              Loading
            </Spinner>
          ) : (
            <div style={{ height: '700px' }}>
           
              <Calendar
                localizer={localizer}
                events={inspections}
                startAccessor="start"
                endAccessor="end"

                style={{ height: 'calc(100% - 50px)' }}
                onSelectEvent={handleSelectEvent}
                components={{
                  event: EventComponent
                }}
              
               
                views={[MONTH_VIEW, WEEK_VIEW]}
                defaultView={WEEK_VIEW}
              />
            </div>
          )}
        </CardBody>
      </Card>

      {/* Inspection Details Modal */}
      <Modal isOpen={isModalOpen} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>
          Inspection Details
        </ModalHeader>
        <ModalBody>
          {selectedInspection && (
            <div>
              <h5>Asset ID: {selectedInspection.CrossingInfo.AssetID}</h5>
              <p>Location: {selectedInspection.CrossingInfo.Location}</p>
              <p>Date: {dateFromObjectId(selectedInspection._id).toLocaleString()}</p>
              {selectedInspection.Inspection_Report && (
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${window.location.protocol}//${window.location.host}/uploads/reports/${selectedInspection.Inspection_Report}`}
                  >
                    View Report
                  </a>
                </p>
              )}
            </div>
          )}
        </ModalBody>
      </Modal>
    </Page>
  );
};

export default InspectionsCalendarPage;