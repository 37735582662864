// This file has been refactored. Please use the new component at:
// src/components/Gateway/GatewayListCard/index.js
//export { default } from '../Gateway/GatewayListCard';

import backgroundImage from '../../assets/img/slmgatewayIcon.png';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Button,
  Card,
  CardImg,
  CardHeader,
  CardImgOverlay,
  CardTitle,
  CardText,
  CardBody,
  Container,
  FormGroup,
  Input,
  CustomInput,
} from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import EIPList, {
  propTypes as EIPListpropTypes,
} from '../../components/EdgeImageProcesserList';

import { FaPencilAlt, FaSave, FaEraser, FaCog } from 'react-icons/fa';

// Default settings configuration
const defaultSettings = {
  weeklyInspection: {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false
  },
  flashRate: {
    Min: 35,
    Max: 65
    
  },
  generalSettings: {
    notifications: true,
    autoInspection: false
  }
};



function buildUrl(url, parameters) {
  let qs = '';
  for (const key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      const value = parameters[key];
      qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
    }
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1); //chop off last "&"
    url = url + '?' + qs;
  }

  return url;
}

let defaultCrossingInfo = {
  Name : "TestCrossing",
  AssetID : "1234",
  Division : "MEDICINE HAT 2",
  SubDivision : "BROOKS 5",
  MilePost : "BROO 152.91 - 152.91",
  Location : "Range Road 282 - West of Dalemead",
  Province : "BC"
}
let EmptyCrossingInfo = {
  Name : "",
  AssetID : "",
  Division : "",
  SubDivision : "",
  MilePost : "",
  Location : "",
  Province : ""
}

const GatewayListCard = ({
  image,
  title,
  subtitle,
  eIPList,
  onConfigurationChange,
  ...restProps
}) => {
  let finalArr = [],
    columns = [];
    const toggle = (id,Name,CrossingInfo) => 
    {
      setselectedMAC(id);
        setCrossingName(Name);  
        setCrossingInfo(CrossingInfo);  
        setModal(!modal);
          console.log(id);
        
         //

    }
  // Modal open states
  const [modal, setModal] = React.useState(false);
  const [settingsModal, setSettingsModal] = React.useState(false);
  const [selectedMAC, setselectedMAC] = React.useState("");
  const [crossingName, setCrossingName] = React.useState("");
  const [crossingInfo, setCrossingInfo] = React.useState({});
  const [settings, setSettings] = React.useState(defaultSettings);

  const toggleSettings = (id, Name,crossingInfo) => {
    setselectedMAC(id);
    setCrossingName(Name);
    setCrossingInfo(crossingInfo);
    // Load crossing-specific settings or use defaults
    setSettings(crossingInfo?.settings || defaultSettings);
    setSettingsModal(!settingsModal);
  };

  const handleWeeklySchedule = (day) => {
    setSettings({
      ...settings,
      weeklyInspection: {
        ...settings.weeklyInspection,
        [day]: !settings.weeklyInspection[day]
      }
    });
  };

  const handleFlashRateChange = (type, value) => {
    setSettings({
      ...settings,
      flashRate: {
        ...settings.flashRate,
        [type]: parseFloat(value) || 0
      }
    });
  };

  const handleGeneralSettings = (setting) => {
    setSettings({
      ...settings,
      generalSettings: {
        ...settings.generalSettings,
        [setting]: !settings.generalSettings[setting]
      }
    });
  };

  const saveSettings = () => {
    const updatedCrossingInfo = {
      ...crossingInfo,
      settings: settings
    };
    updateCrossingInfo(selectedMAC,crossingName,updatedCrossingInfo);
    setSettingsModal(false);
  };

  const updateCrossingInfo=async (ID,CrossingName,CrossingInfo)=>{
    try {
  
  const requestOptions = {
    method: 'PUT',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ID: ID,Name:CrossingName,CrossingInfo: CrossingInfo})
  };
  const response = await fetch(
    buildUrl('/api/crossing', {
      
    }), requestOptions);
  
    console.log(response.json());
  } catch (error) {
    console.log('error', error);
  }

  
  }


  const updateCrossingName=async (ID,CrossingName,CrossingInfo)=>{
    try {
  
  const requestOptions = {
    method: 'PUT',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ID: ID,Name:CrossingName,CrossingInfo: CrossingInfo})
  };
  
  const response = await fetch(
    buildUrl('/api/crossing', {
      
    }), requestOptions);
  
    console.log(response.json());
  } catch (error) {
    console.log('error', error);
  }

  
  }

  const clear = () => 
  {
    setCrossingName("");
    setCrossingInfo(EmptyCrossingInfo);
  }
    //

    const onCrossingNameChange=(event)=>
    {
      setCrossingName(event.target.value);
    }

    const handleCrossingInfoChanged=Key => e =>
    {
      //e.target.preventDefault();
      crossingInfo[Key]=e.target.value;
      let shallow = Object.assign({}, crossingInfo);
      setCrossingInfo(shallow);
    }
  
  const save = () =>
  {
    console.log(crossingName);
    updateCrossingName(selectedMAC,crossingName,crossingInfo);
  }
  let result=null;
  if(!isEmpty(crossingInfo))
  {

     result= getCrossingInfoDiv(crossingInfo, handleCrossingInfoChanged);  
  }else{

   result= getCrossingInfoDiv(defaultCrossingInfo, handleCrossingInfoChanged);
   setCrossingInfo(defaultCrossingInfo);
  }


  let eipListMap = eIPList.map(
    ({ ID, Name, NodeCount, MACAddress, UpdatedAt, Nodes ,CrossingInfo} = {}, index) => {
      //  console.log(this);

      return (
        // columns.push(

        <Col
          md={{ size: 6, offset: 0 }}
          sm={12}
          xs={12}
          className="mb-3"
          key={index}
        >
          <Card className="flex-row">
            <CardImg
              className={index % 2 === 0 ? 'card-img-right' : 'card-img-left'}
              src={image}
              style={{ width: 'auto', height: 150, margin: 'auto' }}
            />
            <CardBody>

              


              <CardTitle >             
              
                <b>Crossing Name:</b> {Name}                  
             
              
                <div className="float-right">
                  <Button outline size="sm" onClick={()=>{toggle(MACAddress,Name,CrossingInfo);}} className="mr-2">
                    <FaPencilAlt/>
                  </Button>
                  <Button outline size="sm" onClick={()=>{toggleSettings(MACAddress, Name,CrossingInfo);}}>
                    <FaCog/>
                  </Button>
                </div>
              </CardTitle>
              {CrossingInfo &&
              <div>
                  <Label>
                    <b style={{marginRight:"5px"}}>Asset ID:  </b>
                     {CrossingInfo.AssetID}
                  </Label>
                </div>}
              <div>
                <Label>
                  <b>SLM Node Count: </b>
                  {NodeCount}
                </Label>
              </div>
              <div>
                <Label>
                  <b>EIP ID: </b>
                  {MACAddress}
                </Label>
              </div>
            
                <div>
                    {UpdatedAt && (
                      <Label>
                        <b>Updated At: </b>{' '}
                        {moment(UpdatedAt).fromNow() +
                          ' at ' +
                          moment(UpdatedAt).format('LLL')}{' '}
                      </Label>
                    )}
                  </div><Button
                    mindex={index}
                    size="sm"
                    onClick={event => {
                      console.log();
                      var index = event.target.attributes.mindex.value;
                      onConfigurationChange(index, 'slm');
                    } }
                  >
                    Inspection
                  </Button>
            </CardBody>
          </Card>
        </Col>

        //)
      );
      // after three items add a new row
      // if((index) % 2 === 0) {
      //   finalArr.push(<Row>{columns}</Row>);
      //   columns = [];
      // }
    },
  );

  return (  
    <>
     
    <Row>{eipListMap}</Row>
    <Modal isOpen={modal} toggle={toggle} contentClassName="custom-modal-style">
    <ModalHeader
        toggle={toggle}>Modify</ModalHeader>
    <ModalBody>
      <div  className='mx-8 my-4'> <b  style={{marginLeft:"35px"}}>Crossing Name: </b><span style={{margin:"10px"}}> </span>
      <input type="text" placeholder={crossingName} onChange={onCrossingNameChange} value={crossingName} className='mx-12 my-10'/>
      </div>
       <Container>
          <Row className='mx-2 my-4' key={crossingInfo}>{ result}
          </Row></Container>
      
     
      
      {/* <div  className='mx-2 my-2'>Asset ID: 
      <input type="text" placeholder={crossingInfo.AssetID} onChange={(e)=>{handleCrossingInfoChanged(e,crossingInfo.AssetID); }} value={crossingInfo.AssetID} className='mx-2 my-2'/>
      </div>
      <div  className='mx-2 my-2'> MilePost: 
      <input type="text" placeholder={crossingInfo.MilePost} onChange={(e)=>{handleCrossingInfoChanged(e,crossingInfo.MilePost); }} value={crossingInfo.MilePost} className='mx-2 my-2'/>
      </div>
      <div  className='mx-2 my-2'>Division: 
      <input type="text" placeholder={crossingInfo.Division} onChange={(e)=>{handleCrossingInfoChanged(e,crossingInfo.Division); }} value={crossingInfo.Division} className='mx-2 my-2'/>
      </div>
      <div >  */
      }
      <div >
    <Button variant="primary"  type="button"  size="sm"  onClick={save} outline className='mx-2 my-4'> <span> Save</span> <FaSave/> </Button>
              <Button variant="primary"  size="sm"  type="button"  onClick={clear} outline className='mx-2 my-4' ><span> Clear</span> <FaEraser/> </Button>
              </div>
                </ModalBody>
                
                <ModalFooter>
                    <Button color="primary" size="sm"  onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
            
            <Modal isOpen={settingsModal} toggle={toggleSettings} contentClassName="custom-modal-style">
              <ModalHeader toggle={toggleSettings}>Crossing Settings</ModalHeader>
              <ModalBody>
                <div className="mb-4">
                  <h5>Weekly Inspection Schedule</h5>
                  <FormGroup>
                    {Object.keys(settings.weeklyInspection).map(day => (
                      <CustomInput
                        key={day}
                        type="checkbox"
                        id={`day-${day}`}
                        label={day.charAt(0).toUpperCase() + day.slice(1)}
                        checked={settings.weeklyInspection[day]}
                        onChange={() => handleWeeklySchedule(day)}
                      />
                    ))}
                  </FormGroup>
                </div>
                
                <div className="mb-4">
                  <h5>Flash Rate Configuration</h5>
                  <FormGroup>
                    {Object.entries(settings.flashRate).map(([type, value]) => (
                      <div key={type} className="mb-2">
                        <Label for={`rate-${type}`}>{type.charAt(0).toUpperCase() + type.slice(1)} Rate</Label>
                        <Input
                          type="number"
                          id={`rate-${type}`}
                          value={value}
                          onChange={(e) => handleFlashRateChange(type, e.target.value)}
                          step="0.1"
                          min="30"
                          max="65"
                        />
                      </div>
                    ))}
                  </FormGroup>
                </div>

                <div>
                  <h5>General Settings</h5>
                  <FormGroup>
                    {Object.entries(settings.generalSettings).map(([setting, value]) => (
                      <CustomInput
                        key={setting}
                        type="switch"
                        id={`setting-${setting}`}
                        label={setting.charAt(0).toUpperCase() + setting.slice(1)}
                        checked={value}
                        onChange={() => handleGeneralSettings(setting)}
                      />
                    ))}
                  </FormGroup>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={saveSettings}>Save Settings</Button>
                <Button color="secondary" onClick={() => setSettingsModal(false)}>Cancel</Button>
              </ModalFooter>
            </Modal>
            
  </>
  );
};

GatewayListCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  eIPList: EIPListpropTypes.eIPList,
  onConfigurationChange: PropTypes.func.isRequired,
};

const GatewayList = withRouter(GatewayListCard);

GatewayListCard.defaultProps = {
  image: backgroundImage,
  title: 'Gateway ',
  subtitle: 'working on...',
  onConfigurationChange: () => {},
};

const isEmpty = (obj) => {
  for(let key in obj) {
      if(obj.hasOwnProperty(key))
          return false;
  }
  return true;
}
export default GatewayList;
function getCrossingInfoDiv(crossingInfo, handleCrossingInfoChanged) {
  
  return Object.keys(crossingInfo).map(key => {

    return (<div key={key} className='mx-6 my-2'>
      <Col>
    <b>   {key.replace(/([a-z])([A-Z])/g, '$1 $2').trim()} </b></Col> 
       
       <Col> <input type="text" placeholder={key} onChange={handleCrossingInfoChanged(key)} value={crossingInfo[key]} className='mx-8 my-2' /></Col></div>);


  });
}

