import React from 'react';
import data from './data';
import { NodeCard } from '../components/Card';
import { GatewayListCard } from '../components/Card';
import { ImageCard } from '../components/Card';
import { useDevices } from '../service/DeviceService';
import { socketConnect } from '../SocketIO';

import {
  Row,
  Col,
} from 'reactstrap';

import Page from '../components/Page';

function DevicesPage(props) {
  const { devices, error, isLoading } = useDevices();
  const [selectedDevice, setSelectedDevice] = React.useState(undefined);
  const [newImage, setNewImage] = React.useState(undefined);

  const buildUrl = (url, parameters) => {
    let qs = '';
    for (const key in parameters) {
      if (parameters.hasOwnProperty(key)) {
        const value = parameters[key];
        qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
      }
    }
    if (qs.length > 0) {
      qs = qs.substring(0, qs.length - 1);
      url = url + '?' + qs;
    }
    return url;
  }

  const ConfigurtionViewClick = (index, typ) => {
    let device = devices[Number(index)];
    if (typ === 'slm') {
      props.history.push({
        pathname: '/devices/slm',
        state: {
          from: props.location,
          data: device,
        },
      });
    } else if(typ==='glm') {
      props.history.push({
        pathname: '/devices/glm',
        state: {
          from: props.location,
          MacAddress: device.MACAddress,
        },
      });
    } else if(typ==='summury') {
      props.history.push({
        pathname: '/devices/summury',
        state: {
          from: props.location,
          MacAddress: device.MACAddress,
        },
      });
    } else if(typ==='inspection') {
      props.history.push({
        pathname: '/devices/inspection',
        state: {
          from: props.location,
          MacAddress: device.MACAddress,
        },
      });
    }
    setSelectedDevice(device);
  };

  const handleCaptureImage = (index) => {
    let data = { Command: 'CaptureImage', Node_ID: [index + 1] };
    fetch(
      buildUrl('/api/commands', {
        mac_address: selectedDevice.MACAddress,
      }),
      {
        method: 'PUT',
        mode: 'cors',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then(res => res.json())
      .then(response => console.log('Success:', JSON.stringify(response)))
      .catch(error => console.error('Error:', error));
  }

  React.useEffect(() => {
    if (props.location.state) {
      const device = devices?.find(d => d.id === props.location.state.id);
      setSelectedDevice(device);

      props.history.push({
        pathname: '/devices/slm',
        state: {
          from: props.location,
          data: device,
        },
      });
    }

    props.location.state = null;

    props.socket.on('detectionUpdateMessage', function (data) {
      if (devices) {
        const result = devices.map((eip) => {
          if (eip.MACAddress === data.GatewayMacAddress) {
            var nodes = Object.values(data.Data.Value);
            eip.Nodes = nodes;
            var copiedObj = JSON.parse(JSON.stringify(eip));
            setSelectedDevice(copiedObj);
          }
          return eip;
        });
        console.log(result);
      }
    });

    props.socket.on('newimage', function (data) {
      setNewImage(JSON.parse(data));
      console.log('new image received');
    });
  }, [props.location, props.socket, devices]);

  if (error) return 'An error has occurred.';
  if (isLoading) return 'Loading...';

  return (
    <Page
      className="ButtonPage"
      title="Crossings"
      breadcrumbs={[{ name: 'Crossings', active: true }]}
    >
      {selectedDevice && (
        <NodeCard
          selectDevice={selectedDevice}
          Nodes={selectedDevice.Nodes}
          GateWayId={selectedDevice.ID}
          NodeId={selectedDevice.MACAddress}
          MACAddress={selectedDevice.MACAddress}
          Name={selectedDevice.Name}
          handleCaptureImage={handleCaptureImage}
        />
      )}

      {selectedDevice === undefined && devices && (
        <GatewayListCard
          eIPList={devices}
          onConfigurationChange={ConfigurtionViewClick}
        ></GatewayListCard>
      )}

      {
        <Row>
          <Col md="6" sm="12" xs="12">
            {newImage && (
              <ImageCard newImage={newImage}></ImageCard>
            )}
          </Col>
        </Row>
      }
    </Page>
  );
}

export default socketConnect(DevicesPage);