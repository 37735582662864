import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TablePagination from './TablePagination';
import {
  Table, Badge, 
  Label
} from 'reactstrap';


import _ from 'lodash';
import { getPageCount } from '../utils/utils';
import { Spinner } from 'reactstrap';
import { RiInformationLine,RiSpeakerFill } from 'react-icons/ri';
import { getColor } from '../utils/colors';
import { round } from '../utils/utils';
import FlashRateInformation from './FlashRateInformation';



function tableHeadings(DetectionStatus) {
  
  let headings = ['Event Time'];
  if (DetectionStatus) {
    DetectionStatus.map(item => {
      let Nodes = Object.values(item.Data.Value);
      let cnt = 0;     
      Nodes.forEach(node => {
        headings.push(node.NodeMacAddress);
        return node.NodeMacAddress;
      });
    });
  }

  return _.uniq(headings);
}

function rgb(r, g, b) {
  return [r, g, b].map(function (v) {
    return ('0' +
      Math.min(Math.max(parseInt(v), 0), 255)
        .toString(16)
    ).slice(-2);
  }).join('');
}

function buildUrl(url, parameters) {
  let qs = '';
  for (const key in parameters) {
    if (parameters.hasOwnProperty(key)) {
      const value = parameters[key];
      qs += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
    }
  }
  if (qs.length > 0) {
    qs = qs.substring(0, qs.length - 1); //chop off last "&"
    url = url + '?' + qs;
  }

  return url;
}

const HistoryTable = ({
  MACAddress,
  Nodes,
  // currentPage,
  // pagesCount,
  // handlePageClick,
  // handlePreviousClick,
  // handleNextClick,
  //pageSize,
}) => {
  let [headings, setHeadings] = useState();
  let [loading, setLoading] = useState(true);
  const [DetectionStatus, setDetectionStatus] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pagesCount, setPagesCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  const [lightSensorData, setLightSensorData] = useState({});
  const [NodeNameLookupTable, setNodeNameLookupTable] = useState({});


  if(Nodes){
    Nodes.map(item => {
         if(item.NodeName)
         {
          NodeNameLookupTable[item.NodeMacAddress+''] =item.NodeName;
         }else 
         {
          NodeNameLookupTable[item.NodeMacAddress+''] =item.NodeMacAddress;
         }
        
        });  
  }
  

  const toggle = () => {

    setModal(!modal);
    //console.log(LightData);

    //

  }
  // Modal open state
  const [modal, setModal] = React.useState(false);

  const getData = async () => {
    try {
      const response = await fetch(
        buildUrl('/api/getdetectionstatus', {
          mac_address: MACAddress,
        }),
        {
          method: 'GET', // or 'PUT'
          mode: 'cors',
          //body: JSON.stringify(data), // data can be `string` or {object}!
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      const json = await response.json();
      console.log(json);
      setDetectionStatus(json);
      setHeadings(tableHeadings(json,Nodes));
      setPagesCount(getPageCount(json.length, pageSize));
      setLoading(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  function handlePageClick(e, index) {
    e.preventDefault();
    setCurrentPage(index);
  }
  function handleNextClick(e) {
    e.preventDefault();
    setCurrentPage(currentPage + 1);
  }
  function handlePreviousClick(e) {
    e.preventDefault();
    setCurrentPage(currentPage - 1);
  }

  useEffect(() => {
    getData();
  }, []);
  let cnt = 0;
  return (
    <React.Fragment>
      {loading && (
        <Spinner
          style={{
            position: 'absolute',
            width: '50px',
            height: '50px',
            top: '100%',
            left: '50%',
          }}
        >
          Loading
        </Spinner>
      )}

      {FlashRateInformation(modal, toggle, lightSensorData)}



      {/* <div>{MACAddress}</div>
      {DetectionStatus.map((item, i) => {
        let val2 = Object.values(item.Data.Value);
        let val = val2.map((node, x) => {
          return (
            'MAC: ' +
            node.NodeMacAddress +
            ' Blink Count : ' +
            node.BlinkCount +
            ' '
          );
        });
        return <li key={i}> {val}</li>;
      })} */}
      <Table striped bordered responsive>
        <thead style={{ textAlign: 'center' }}>
          <tr>
            {headings &&
              headings.length > 0 &&
              headings.map((heading, index) => {
                if (index === 0) {
                  return <th>{heading}</th>;
                } else {
                  
                  if(NodeNameLookupTable[heading])
                  {
                    return <th>{NodeNameLookupTable[heading]+` (${heading})`}</th>;  
                  }else {

                  return <th>{`Node ${++cnt} (${heading})`}</th>;
                  }
                }
              })}
          </tr>
        </thead>
        <tbody>
          {DetectionStatus.length > 0 &&
            DetectionStatus.slice(
              currentPage * pageSize,
              (currentPage + 1) * pageSize,
            ).map((Device, index) => {
              let Nodes = Object.values(Device.Data.Value);
              return (
                <tr key={Device._id}>
                  <td style={{ width: 210 }}>
                    {dateFromObjectId(Device._id).toLocaleString()}
                  </td>
                  {headings &&
                    headings.length > 0 &&
                    headings.map((heading, index) => {
                      if (index > 0) {
                        let filterArray = _.filter(Nodes, {
                          NodeMacAddress: heading,
                        });
                        let flashRate=0;
                        const badgeinfo =filterArray[0] && filterArray[0].NodeMacAddress && <Badge  color="light"   style={{align:"center",display:"table-inline",color:"gray",background:"transparent"}} href={'#' + filterArray[0].NodeMacAddress}  onClick={() => {
                          //  console.log(filterArray[0]);
                          if (filterArray[0].LightSensorData !== undefined) {
                            let lightdata = filterArray[0].LightSensorData;
                            let factor = lightdata.AvgLux / 179;

                            lightdata.AvgRedColor = rgb(lightdata.AvgRed * factor, 0.01 * factor, 0.01 * factor);
                            lightdata.MinRedColor = rgb(lightdata.AvgRed * (lightdata.MinLux / 179), 0.01 * factor, 0.01 * factor);
                            lightdata.MaxRedColor = rgb(lightdata.AvgRed * (lightdata.MaxLux / 179), 0.01 * factor, 0.01 * factor);
                            flashRate = flashrateCalculation(lightdata);
                            //lightdata.crossingDuration =;
                            setLightSensorData(lightdata);
                            toggle();
                          }
                          else {
                            //Do nothing ... for now
                          }

                    

                        }}><RiInformationLine  size={"1.5em"}  /></Badge>;
                        let bellResult = filterArray[0] && filterArray[0].IsBellEnabled && filterArray[0].IsBellDetected ? <Badge color="success"> <RiSpeakerFill></RiSpeakerFill>Bell Detected</Badge> : <Badge color="danger">Bell Not Detected</Badge>;
                        return (
                          filterArray[0] && (filterArray[0].FlashRate || filterArray[0].BlinkCount) ? (
                            <td style={{ textAlign: 'center' }}>
                              {filterArray[0].FlashRate && (
                                <>
                                  <b>Flashes Per Minute:</b>         {filterArray[0].FlashRate.toFixed(2)}
                                  <br/>
                                </>
                              )}
                              {filterArray[0].BlinkCount && (
                                <>
                                  <b>Total Flash Count:</b>        {filterArray[0].BlinkCount}
                                </>
                              )}
                              {filterArray[0].LightSensorData && badgeinfo} <br/>
                              {filterArray[0].IsBellEnabled && bellResult}
                            </td>
                          ) : <td style={{ textAlign: 'center',  fontSize: '0.85rem', whiteSpace: 'nowrap', padding: '0.5rem' }} > No Data  </td>
                        );
                      }
                    })}
                </tr>
              );
            })}
        </tbody>
      </Table>
      {pagesCount > 1 && (
        <TablePagination
          pagesCount={pagesCount}
          currentPage={currentPage}
          handlePageClick={handlePageClick}
          handlePreviousClick={handlePreviousClick}
          handleNextClick={handleNextClick}
        />
      )}
    </React.Fragment>
  );
};

HistoryTable.propTypes = {
  MACAddress: PropTypes.string.isRequired,
  // pagesCount: PropTypes.number.isRequired,
  // currentPage: PropTypes.number.isRequired,
  // pageSize: PropTypes.number.isRequired,
  // handlePageClick: PropTypes.func.isRequired,
  // handlePreviousClick: PropTypes.func.isRequired,
  // handleNextClick: PropTypes.func.isRequired,
};

export default HistoryTable;

var dateFromObjectId = function (objectId) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

export function flashrateCalculation(lightdata) {
  let diff = lightdata.TimeEnd - lightdata.TimeStart;
  let seconds = diff / 1000;
  let minutes = seconds / 60;
  let flashrate = lightdata.BlinkCount / minutes;
  lightdata.crossingDuration =  round(minutes, 2);
  lightdata.FlashRate = round(flashrate, 2);
  return lightdata.FlashRate;
}

