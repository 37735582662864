import React, { useState } from 'react'
import { Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import useSWR from 'swr'
import { buildUrl } from '../utils/utils';

const GLMData = (props) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  })

  const columns = [
    {
      title: 'Crossing Event ID',
      dataIndex: 'crossingEventId',
      key: 'crossingEventId',
    },
    {
      title: 'Start Time',
      dataIndex: 'startTime',
      key: 'startTime',
    },
    {
      title: 'EIP Mac Address',
      dataIndex: 'gatewayMacAddress',
      key: 'gatewayMacAddress',
    },
    {
      title: 'GLM Mac Address',
      dataIndex: 'nodeMacAddress',
      key: 'nodeMacAddress',
    },
    {
      title: 'Gatearm Detected',
      dataIndex: 'gatearmDetected',
      key: 'gatearmDetected',
      render: (value) => String(value).charAt(0).toUpperCase() + String(value).slice(1),
    },
    {
      title: 'Gatearm Down Angle degree(°)',
      dataIndex: 'gatearmDownAngle',
      key: 'gatearmDownAngle',
    },
    {
      title: 'Gatearm Up Angle  degree(°)',
      dataIndex: 'gatearmUpAngle',
      key: 'gatearmUpAngle',
    },
    {
      title: 'Gatearm Descent Time',
      dataIndex: 'gatearmDescentTime',
      key: 'gatearmDescentTime',
    },
    {
      title: 'Gatearm Ascent Time',
      dataIndex: 'gatearmAscentTime',
      key: 'gatearmAscentTime',
    },
    {
      title: 'Tip Broken',
      dataIndex: 'tipBroken',
      key: 'tipBroken',
      render: (value) => String(value).charAt(0).toUpperCase() + String(value).slice(1),
    },
    {
      title: 'Tip Light Detected',
      dataIndex: 'tipLightDetected',
      key: 'tipLightDetected',
      render: (value) => String(value).charAt(0).toUpperCase() + String(value).slice(1),
    },
    {
      title: 'Middle Light Detected',
      dataIndex: 'middleLightDetected',
      key: 'middleLightDetected',
      render: (value) => String(value).charAt(0).toUpperCase() + String(value).slice(1),
    },
    {
      title: 'Base Light Detected',
      dataIndex: 'baseLightDetected',
      key: 'baseLightDetected',
      render: (value) => String(value).charAt(0).toUpperCase() + String(value).slice(1),
    },
    {
      title: 'Tip Light Blink Count',
      dataIndex: 'tipLightBlinkCount',
      key: 'tipLightBlinkCount',
    },
    {
      title: 'Middle Light Blink Count',
      dataIndex: 'middleLightBlinkCount',
      key: 'middleLightBlinkCount',
    },
    {
      title: 'Base Light Blink Count',
      dataIndex: 'baseLightBlinkCount',
      key: 'baseLightBlinkCount',
    },
    {
      title: 'Tip Light Flash Rate',
      dataIndex: 'tipLightFlashRate',
      key: 'tipLightFlashRate',
    },
    {
      title: 'Middle Light Flash Rate',
      dataIndex: 'middleLightFlashRate',
      key: 'middleLightFlashRate',
    },
    {
      title: 'Base Light Flash Rate',
      dataIndex: 'baseLightFlashRate',
      key: 'baseLightFlashRate',
    },
  ]

      const fetcher = async (url) => {
        const response = await fetch(url)
        const data = await response.json()
    
        const result = data.map(item => Object.entries(item.Data.GLM).map(([nodeMacAddress, glmData]) => ({
          key: `${item.Data.CrossingEventID || ''}`,
          crossingEventId: item.Data.CrossingEventID,
          startTime: new Date(item.Data.StartTime * 1000).toLocaleString(),         
          gatewayMacAddress:`${item.GatewayMacAddress || ''}`,
          nodeMacAddress: glmData.NodeMacAddress,
          gatearmDetected: glmData.GatearmDetected,
          gatearmDownAngle: glmData.GatearmDetected ? glmData.GatearmDownAngle+'°' : 'N/A',
          gatearmUpAngle: glmData.GatearmDetected ? glmData.GatearmUpAngle+'°' : 'N/A',
          gatearmDescentTime: glmData.GatearmDetected ? glmData.GatearmDescentTime.toFixed(2) + ' seconds' : 'N/A',
          gatearmAscentTime: glmData.GatearmDetected ? glmData.GatearmAscentTime.toFixed(2) + ' seconds' : 'N/A',
          tipBroken: glmData.GatearmDetected ? glmData.TipBroken : 'N/A',
          tipLightDetected: glmData.GatearmDetected ? glmData.TipLightDetected : 'N/A',
          middleLightDetected: glmData.GatearmDetected ? glmData.MiddleLightDetected : 'N/A',
          baseLightDetected: glmData.GatearmDetected ? glmData.BaseLightDetected : 'N/A',
          tipLightBlinkCount: glmData.GatearmDetected ? glmData.TipLightBlinkCount : 'N/A',
          middleLightBlinkCount: glmData.GatearmDetected ? glmData.MiddleLightBlinkCount : 'N/A',
          baseLightBlinkCount: glmData.GatearmDetected ? glmData.BaseLightBlinkCount : 'N/A',
          tipLightFlashRate: glmData.GatearmDetected ? glmData.TipLightFlashRate : 'N/A',
          middleLightFlashRate: glmData.GatearmDetected ? glmData.MiddleLightFlashRate.toFixed(2) : 'N/A', 
          baseLightFlashRate: glmData.GatearmDetected ? glmData.BaseLightFlashRate.toFixed(2) : 'N/A',
        }))).flat()

        result.sort((a, b) => new Date(b.startTime) - new Date(a.startTime))
        return result;     
       } 

      const { data, error } = useSWR(buildUrl('/api/getglmdata', { mac_address: props.MacAddress }), fetcher)

      const handlePageChange = (pageNumber) => {
        setPagination(prev => ({
          ...prev,
          current: pageNumber
        }));
      };

      const totalPages = data ? Math.ceil(data.length / pagination.pageSize) : 0;

      return (
        <div style={{ padding: '4px', width: '85%', overflowX: 'auto' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: '100%' }}>
            <Table striped bordered hover responsive size="sm">
              <thead>
                <tr>
                  {columns.map(column => (
                    <th key={column.key} style={{ fontSize: '0.85rem', whiteSpace: 'nowrap', padding: '0.5rem' }}>{column.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data?.slice((pagination.current - 1) * pagination.pageSize, pagination.current * pagination.pageSize).map(row => (
                  <tr key={row.key}>
                    {columns.map(column => (
                      <td key={`${row.key}-${column.key}`} style={{ fontSize: '0.85rem', whiteSpace: 'nowrap', padding: '0.5rem' }}>
                        {column.render ? column.render(row[column.dataIndex]) : row[column.dataIndex]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Pagination>
              <PaginationItem disabled={pagination.current === 1}>
                <PaginationLink previous onClick={() => handlePageChange(pagination.current - 1)} />
              </PaginationItem>
              
              {[...Array(totalPages)].map((_, index) => (
                <PaginationItem key={index + 1} active={pagination.current === index + 1}>
                  <PaginationLink onClick={() => handlePageChange(index + 1)}>
                    {index + 1}
                  </PaginationLink>
                </PaginationItem>
              ))}

              <PaginationItem disabled={pagination.current === totalPages}>
                <PaginationLink next onClick={() => handlePageChange(pagination.current + 1)} />
              </PaginationItem>
            </Pagination>
          </div>
        </div>
      )
}

export default GLMData